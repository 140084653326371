import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const withLang = (Component) => {
  return (props) => {
    const location = useLocation();
    const history = useHistory();
    const query = new URLSearchParams(location.search);
    const [localeLang, setLocaleLang] = useState('')
    const lang = query.get('lang');

    useEffect(() => {
      let secLag = localStorage.getItem('lang') || 'en';
      setLocaleLang(secLag);
    
      //if current quert lang is difference between localstorage saved lang tha update url query
      if (lang !== secLag) {
        const newUrl = new URL(window.location);
        const params = newUrl.searchParams;
        params.set('lang', secLag);
    
        history.push(newUrl.pathname + '?' + params.toString());
      }
    }, [lang, history, location]);
    
    return <Component {...props} localeLang={localeLang} />;
  };
};

export default withLang;
